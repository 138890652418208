<style scoped>
</style>

<template>
    <v-container>
        <v-row>
            <v-col class="pa-0" style="max-width:60px;">
                <person-avatar :avatar="item.avatar" :name="item.name"></person-avatar>
            </v-col>
            <v-col class="pl-0 pt-0 pb-0" style="text-align:left;display:flex;align-items:center;position:relative;">
                <v-btn v-if="this.button" small depressed color="secondary" style="position:absolute;right:10px;" @click="$emit('button', item)">{{ button.title }}</v-btn>
                <v-container class="pa-0">
                    <v-row>
                        <v-col :class="{ 'pb-0': date }" style="">
                            <div style="font-weight:bold;">{{ prefix ? prefix + ": " : "" }}{{ item.name }}</div>
                        </v-col>
                    </v-row>
                    <v-row v-if="date">
                        <v-col class="pt-0">
                            {{ date }}
                        </v-col>
                    </v-row>                    
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PersonAvatar from './person.avatar.vue';
//import events from "../../app/app.events";

export default {
    props: {
        item: {},
        prefix: {},
        date: {},
        button: {}
    },
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
        PersonAvatar
    }
}
</script>